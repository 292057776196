import { Box, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import getRatio from "./getRatio";
import { fileThumb } from "../file-thumbnail";

const Image = forwardRef(
  (
    {
      ratio,
      disabledEffect = false,
      effect = "blur",
      format,
      name,
      tooltip,
      sx,
      ...other
    },
    ref
  ) => {
    const content = (
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        effect={disabledEffect ? undefined : effect}
        placeholderSrc={
          disabledEffect ? "/assets/transparent.png" : fileThumb(format)
        }
        onError={(e) => {
          e.target.src = fileThumb(format) || "/assets/placeholder.svg"; // Fallback si la imagen falla
        }}
        sx={{ width: 100, height: 100, objectFit: "cover" }}
        {...other}
      />
    );

    if (ratio) {
      return (
        <Box
          ref={ref}
          component="span"
          sx={{
            width: 1,
            lineHeight: 1,
            display: "block",
            overflow: "hidden",
            position: "relative",
            pt: getRatio(ratio),
            "& .wrapper": {
              top: 0,
              left: 0,
              width: 1,
              height: 1,
              position: "absolute",
              backgroundSize: "cover !important",
            },
            ...sx,
          }}
        >
          {tooltip ? <Tooltip title={name}>{content}</Tooltip> : content}
        </Box>
      );
    }

    return (
      <Box
        ref={ref}
        component="span"
        sx={{
          lineHeight: 1,
          display: "block",
          overflow: "hidden",
          position: "relative",
          "& .wrapper": {
            width: 1,
            height: 1,
            backgroundSize: "cover !important",
          },
          ...sx,
        }}
      >
        {tooltip ? (
          <Tooltip title={name}>
            <span>{content}</span>
          </Tooltip>
        ) : (
          content
        )}
      </Box>
    );
  }
);

Image.propTypes = {
  sx: PropTypes.object,
  effect: PropTypes.string,
  disabledEffect: PropTypes.bool,
  format: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.bool,
  ratio: PropTypes.oneOf([
    "4/3",
    "3/4",
    "6/4",
    "4/6",
    "16/9",
    "9/16",
    "21/9",
    "9/21",
    "1/1",
  ]),
};

export default Image;
