import PropTypes from "prop-types";
import Image from "../../image/image";
import { fileData, fileFormat } from "src/components/file-thumbnail";

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showTooltipSingleImage: PropTypes.bool,
};

export default function SingleFilePreview({ file, showTooltipSingleImage }) {
  if (!file) {
    return null;
  }

  const { name = "", path = "", preview = "" } = fileData(file);

  const imgUrl = typeof file === "string" ? file : preview;

  const format = fileFormat(path || preview);

  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        position: "absolute", // Asegura que la imagen esté bien posicionada
        top: "50%", // Centrado vertical
        left: "50%", // Centrado horizontal
        transform: "translate(-50%, -50%)", // Ajusta el centro exacto
        maxWidth: "100%", // No exceder el contenedor
        maxHeight: "100%", // No exceder el contenedor
        width: "auto", // Ajuste dinámico del ancho
        height: "auto", // Ajuste dinámico del alto
        objectFit: "fill", // Mantiene la proporción y se ajusta sin deformar
      }}
      format={format}
      name={name}
      tooltip={showTooltipSingleImage}
    />
  );
}
