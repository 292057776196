import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Upload from "src/components/upload/upload";
import saveFill from "@iconify/icons-eva/save-outline";
import Icon from "@iconify/react";
import {
  descargarArchivoDesdeDropbox,
  eliminarArchivoDropbox,
  subirArchivoADropbox,
} from "src/utils/dropboxUtils";
import handleError from "src/utils/manejo-errores";
import { AlertaToast } from "src/components/AlertaSwall";
import { clientesApi } from "src/services/clientesApi";

const mostrarAlertaError = (mensaje) => {
  AlertaToast({
    action: mensaje,
    fullText: true,
    type: "error",
    target: document.getElementById("dialog-organigrama-grupo-clientes"),
  }).fire();
};

const mostrarAlertaExito = (grupo, accion) => {
  AlertaToast({
    element: `Organigrama grupo ${grupo}`,
    action: accion,
  }).fire();
};

const MAX_SIZE_FILE = 50 * 1024 * 1024; //50 MB
const MAX_FILES = 1;

const ModalOrganigramaGrupo = ({
  isOpen,
  setOpen,
  title,
  grupo: grupoSeleccionado,
  editar = false,
}) => {
  const [file, setFile] = useState(null);
  const [guardando, setGuardando] = useState(false);
  const [cargandoImagen, setCargandoImagen] = useState(false);
  const [nombreImagenBD, setNombreImagenBD] = useState("");

  const [asociarOrganigramaGrupo] =
    clientesApi.useAsociarOrganigramaGrupoMutation();
  const [actualizarOrganigramaGrupo] =
    clientesApi.useActualizarOrganigramaGrupoMutation();

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    } else {
      setFile(null);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const cargarImagenDropbox = useCallback(async () => {
    if (editar) {
      try {
        setCargandoImagen(true);
        const { urlArchivo, grupo } = grupoSeleccionado;

        const expresionRegular = /\/(archivo_)[^?]+/i;
        //Se extrae de la url el nombre del archivo a eliminar.
        const coincidencia = urlArchivo.match(expresionRegular);
        const nombre = coincidencia ? coincidencia[0] : null;
        setNombreImagenBD(nombre);
        const file = await descargarArchivoDesdeDropbox(
          `Organigramas/${grupo}`,
          nombre
        );

        setFile(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      } catch (error) {
        console.error(error);
        AlertaToast({
          action: "Error al cargar la imagen. Reintente cargando la página",
          fullText: true,
          type: "error",
          target: document.getElementById("dialog-organigrama-grupo-clientes"),
        }).fire();
      } finally {
        setCargandoImagen(false);
      }
    }
  }, [editar, grupoSeleccionado]);

  useEffect(() => {
    cargarImagenDropbox();
  }, [cargarImagenDropbox]);

  useEffect(() => {
    return () => {
      if (file?.preview) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  const guardarNuevoOrganigrama = useCallback(
    async (grupo) => {
      const body = {
        componenteGrupos: "1297451858",
        grupo,
      };

      const bodyConUrl = await subirArchivoADropbox(
        file,
        `Organigramas/${grupo}`,
        body,
        null,
        "urlArchivo"
      );

      const { data, error } = await asociarOrganigramaGrupo(bodyConUrl);

      if (error) {
        mostrarAlertaError("Error al guardar organigrama");
        handleError("guardarNuevoOrganigrama", error);
      } else if (data) {
        mostrarAlertaExito(grupo, "agregado");
        setOpen(false);
      }
    },
    [asociarOrganigramaGrupo, file, setOpen]
  );

  const editarOrganigrama = useCallback(
    async (grupo) => {
      const deleteFileDropbox = await eliminarArchivoDropbox(
        `Organigramas/${grupo}`,
        nombreImagenBD
      );

      if (!deleteFileDropbox || deleteFileDropbox.status !== 200) {
        mostrarAlertaError(
          "Ocurrió un error al actualizar la imagen. Reintente nuevamente"
        );
        return;
      }

      const body = {
        componenteGrupos: "1297451858",
        grupo,
      };

      const bodyConUrl = await subirArchivoADropbox(
        file,
        `Organigramas/${grupo}`,
        body,
        null,
        "urlArchivo"
      );

      const { data, error } = await actualizarOrganigramaGrupo(bodyConUrl);

      if (error) {
        mostrarAlertaError("Error al guardar organigrama");
        handleError("editarOrganigrama", error);
      } else if (data) {
        mostrarAlertaExito(grupo, "actualizado");
        setOpen(false);
      }
    },
    [actualizarOrganigramaGrupo, file, nombreImagenBD, setOpen]
  );

  const guardarOrganigramaGrupo = useCallback(async () => {
    setGuardando(true);
    const { grupo } = grupoSeleccionado;

    try {
      if (!editar) {
        await guardarNuevoOrganigrama(grupo);
      } else {
        await editarOrganigrama(grupo);
      }
    } catch (error) {
      mostrarAlertaError("Error en el servidor");
      handleError("guardarOrganigramaGrupo", error);
    } finally {
      setGuardando(false);
    }
  }, [editar, editarOrganigrama, grupoSeleccionado, guardarNuevoOrganigrama]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-organigrama-grupo-clientes"
      id="dialog-organigrama-grupo-clientes"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        style={styles.title}
        htmlFor="dialog-organigrama-grupo-clientes"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardHeader title={!editar ? "Subir archivo" : "Editar archivo"} />
          <CardContent>
            <Upload
              disabled={guardando}
              file={file}
              loading={cargandoImagen}
              onDrop={handleDropSingleFile}
              onDelete={() => setFile(null)}
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              maxSize={MAX_SIZE_FILE}
              maxFiles={MAX_FILES}
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} color="error" disabled={guardando}>
          Cerrar
        </LoadingButton>
        <LoadingButton
          disabled={!file || guardando}
          startIcon={<Icon icon={saveFill} />}
          color="primary"
          onClick={guardarOrganigramaGrupo}
          loading={guardando}
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ModalOrganigramaGrupo.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  grupo: PropTypes.object,
  editar: PropTypes.bool,
};

const styles = {
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default React.memo(ModalOrganigramaGrupo);
